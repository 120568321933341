

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SearchOptionRow extends Vue {
    @Prop({ required: false })
    public title!: string | undefined;

    @Prop({ required: false })
    public required!: boolean | undefined;
}
