













import container from '@/model/ModelContainer';
import IDropLogDialogState from '@/model/state/dropLog/IDropLogDialogState';
import Util from '@/util/Util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class DropLogDialog extends Vue {
    @Prop({ required: true })
    public isOpen!: boolean;

    private dropLogState: IDropLogDialogState = container.get<IDropLogDialogState>('IDropLogDialogState');

    public isRemove: boolean = false;

    get name(): string {
        return this.dropLogState.getName();
    }

    get content(): string {
        const content = this.dropLogState.getDropLog();

        return content === null ? 'ログファイルがありません' : content;
    }

    /**
     * Prop で受け取った isOpen を直接は書き換えられないので
     * getter, setter を用意する
     */
    get dialogModel(): boolean {
        return this.isOpen;
    }
    set dialogModel(value: boolean) {
        this.$emit('update:isOpen', value);
    }

    @Watch('isOpen', { immediate: true })
    public onChangeState(newState: boolean, oldState: boolean): void {
        if (newState === false && oldState === true) {
            // close
            this.$nextTick(async () => {
                await Util.sleep(100);
                // dialog close アニメーションが終わったら要素を削除する
                this.isRemove = true;
                this.$nextTick(() => {
                    this.isRemove = false;
                });
            });
        }
    }
}
