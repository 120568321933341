














































































































































import SearchOptionRow from '@/components/search/SearchOptionRow.vue';
import container from '@/model/ModelContainer';
import ISearchState from '@/model/state/search/ISearchState';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        SearchOptionRow,
    },
})
export default class SearchRuleOption extends Vue {
    public searchState: ISearchState = container.get<ISearchState>('ISearchState');

    public onClickCancel(): void {
        this.$emit('cancel');
    }

    public onClickAdd(): void {
        this.$emit('add');
    }

    public onClickUpdate(): void {
        this.$emit('update');
    }
}
