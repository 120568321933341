import { render, staticRenderFns } from "./WatchRecordedStreaming.vue?vue&type=template&id=36ceb553&scoped=true&"
import script from "./WatchRecordedStreaming.vue?vue&type=script&lang=ts&"
export * from "./WatchRecordedStreaming.vue?vue&type=script&lang=ts&"
import style0 from "./WatchRecordedStreaming.vue?vue&type=style&index=0&id=36ceb553&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ceb553",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
