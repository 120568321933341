




























import IChannelModel from '@/model/channels/IChannelModel';
import container from '@/model/ModelContainer';
import { ReserveStateData } from '@/model/state/reserve/IReserveStateUtil';
import { ISettingStorageModel } from '@/model/storage/setting/ISettingStorageModel';
import DateUtil from '@/util/DateUtil';
import Util from '@/util/Util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class ReserveDialog extends Vue {
    @Prop({ required: true })
    public isOpen!: boolean;

    @Prop({
        required: true,
    })
    public reserve!: ReserveStateData | null;

    public isRemove: boolean = false;

    private settingModel: ISettingStorageModel = container.get<ISettingStorageModel>('ISettingStorageModel');
    private channelModel: IChannelModel = container.get<IChannelModel>('IChannelModel');

    /**
     * Prop で受け取った isOpen を直接は書き換えられないので
     * getter, setter を用意する
     */
    get dialogModel(): boolean {
        return this.isOpen;
    }
    set dialogModel(value: boolean) {
        this.$emit('update:isOpen', value);
    }

    /**
     * 番組の開始時間の番組表へ飛ぶ
     * @return Promise<void>
     */
    public async gotoGuide(): Promise<void> {
        if (this.reserve === null) {
            return;
        }

        this.dialogModel = false;
        await Util.sleep(300);

        const startAt = DateUtil.getJaDate(new Date(this.reserve.reserveItem.startAt));
        const query: any = {
            time: DateUtil.format(startAt, 'YYMMddhh'),
        };

        const setting = this.settingModel.getSavedValue();
        if (setting.isEnableDisplayForEachBroadcastWave === true) {
            const channel = this.channelModel.findChannel(this.reserve.reserveItem.channelId, true);
            if (channel !== null) {
                query.type = channel.channelType;
            }
        }

        await Util.move(this.$router, {
            path: '/guide',
            query: query,
        });
    }

    /**
     * dialog の表示状態が変更されたときに呼ばれる
     */
    @Watch('isOpen', { immediate: true })
    public onChangeState(newState: boolean, oldState: boolean): void {
        /**
         * dialog を一度開くと v-aplication 直下に要素が追加され、
         * android 使用時に番組表のスクロールが正常にできなくなる
         * そのため一時的に isRemove を true にして要素を削除し、再度描画させている
         */
        if (newState === false && oldState === true) {
            // close
            this.$nextTick(async () => {
                await Util.sleep(100);
                this.isRemove = true;
                this.$nextTick(() => {
                    this.isRemove = false;
                });
            });
        } else if (newState === true && oldState === false) {
            // open
            // extended の URL のリンクを貼る
            this.$nextTick(() => {
                const extended = document.getElementById('reserve-extended');
                if (extended !== null) {
                    let str = extended.innerHTML;
                    str = str.replace(/(http:\/\/[\x21-\x7e]+)/gi, "<a href='$1' target='_blank'>$1</a>");
                    str = str.replace(/(https:\/\/[\x21-\x7e]+)/gi, "<a href='$1' target='_blank'>$1</a>");
                    extended.innerHTML = str;
                }
            });
        }
    }
}
