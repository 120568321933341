









































































import SearchOptionRow from '@/components/search/SearchOptionRow.vue';
import container from '@/model/ModelContainer';
import IManualReserveState from '@/model/state/reserve/manual/IManualReserveState';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        SearchOptionRow,
    },
})
export default class ManualTimeReserveOption extends Vue {
    @Prop({ required: true })
    public isEditMode!: boolean;

    private manualReserveState: IManualReserveState = container.get<IManualReserveState>('IManualReserveState');
}
