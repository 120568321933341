import { render, staticRenderFns } from "./AppContent.vue?vue&type=template&id=2d779d5e&scoped=true&"
import script from "./AppContent.vue?vue&type=script&lang=ts&"
export * from "./AppContent.vue?vue&type=script&lang=ts&"
import style0 from "./AppContent.vue?vue&type=style&index=0&id=2d779d5e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d779d5e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
