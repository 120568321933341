





































import { ProgramStateData } from '@/model/state/reserve/manual/IManualReserveState';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ManualReserveProgramInfo extends Vue {
    @Prop({ required: true })
    public program!: ProgramStateData | null;
}
