








import ReservesCard from '@/components/reserves/ReservesCard.vue';
import { ReserveStateData } from '@/model/state/reserve/IReserveStateUtil';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        ReservesCard,
    },
})
export default class SearchReserves extends Vue {
    @Prop({
        required: true,
    })
    public reserves!: ReserveStateData[];
}
