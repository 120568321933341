










import container from '@/model/ModelContainer';
import IGuideState from '@/model/state/guide/IGuideState';
import IOnAirSelectStreamState from '@/model/state/onair/IOnAirSelectStreamState';
import DateUtil from '@/util/DateUtil';
import Util from '@/util/Util';
import { Component, Vue } from 'vue-property-decorator';
import * as apid from '../../../../api';

interface DisplayChannelItem {
    name: string;
    id: apid.ChannelId;
    index: number | string;
    item: apid.ScheduleChannleItem;
}

@Component({})
export default class Channel extends Vue {
    public guideState: IGuideState = container.get<IGuideState>('IGuideState');

    private streamSelectDialog: IOnAirSelectStreamState = container.get<IOnAirSelectStreamState>('IOnAirSelectStreamState');

    get channelItems(): DisplayChannelItem[] {
        if (typeof this.$route.query.channelId === 'undefined') {
            return this.guideState.getChannels().map(c => {
                return {
                    name: c.name,
                    id: c.id,
                    index: c.id,
                    item: c,
                };
            });
        } else {
            let baseTime = this.guideState.getStartAt();

            return this.guideState.getChannels().map(c => {
                const name = DateUtil.format(DateUtil.getJaDate(new Date(baseTime)), 'MM/dd(w)');
                baseTime += 60 * 60 * 24 * 1000;

                return {
                    name: name,
                    id: c.id,
                    index: name,
                    item: c,
                };
            });
        }
    }

    public async onClick(item: apid.ScheduleChannleItem): Promise<void> {
        // 単局表示の場合は何もしない
        if (typeof this.$route.query.channelId !== 'undefined') {
            return;
        }

        this.streamSelectDialog.open(item);
    }
}
