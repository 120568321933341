

















import container from '@/model/ModelContainer';
import ISnackbarState from '@/model/state/snackbar/ISnackbarState';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Snackbar extends Vue {
    public snackbarState: ISnackbarState = container.get<ISnackbarState>('ISnackbarState');

    get snackbarClass(): any {
        const result: any = {};
        result[this.snackbarState.displayOption.color] = true;

        return result;
    }

    public async onClickButton(): Promise<void> {
        this.snackbarState.isOpen = false;
    }

    public onClose(): void {
        this.snackbarState.close();
    }
}
