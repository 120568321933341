








import EncodeSmallCard from '@/components/encode/EncodeSmallCard.vue';
import { EncodeInfoDisplayData } from '@/model/state/encode/IEncodeState';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as apid from '../../../../api';

@Component({
    components: {
        EncodeSmallCard,
    },
})
export default class EncodeItems extends Vue {
    @Prop({ required: true })
    public items!: EncodeInfoDisplayData[];

    @Prop({ required: true })
    public isEditMode!: boolean;

    public selected(encodeId: apid.EncodeId): void {
        this.$emit('selected', encodeId);
    }
}
