




























import ReserveDialog from '@/components/reserves/ReserveDialog.vue';
import ReserveMenu from '@/components/reserves/ReserveMenu.vue';
import { ReserveStateData } from '@/model/state/reserve/IReserveStateUtil';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        ReserveMenu,
        ReserveDialog,
    },
})
export default class ReservesCard extends Vue {
    @Prop({
        required: true,
    })
    public reserves!: ReserveStateData[];

    @Prop({ required: true })
    public isEditMode!: boolean;

    @Prop({ required: false })
    public needsDecoration: boolean | undefined;

    @Prop({ required: false })
    public disableEdit: boolean | undefined;

    @Prop({ required: false })
    public flat: boolean | undefined;

    public isOpenDialog: boolean = false;
    public dialogReserve: ReserveStateData | null = null;

    public getClass(reserve: ReserveStateData): any {
        const result: any = {};

        if (!!this.flat === false) {
            result['my-3'] = true;
        }

        if (reserve.reserveItem.isSkip === true) {
            result.skip = true;
        } else if (reserve.reserveItem.isConflict === true) {
            result.conflict = true;
        } else if (reserve.reserveItem.isOverlap === true) {
            result.overlap = true;
        } else {
            result.reserve = true;
        }

        if (reserve.isSelected === true) {
            result['selected-color'] = true;
        }

        return result;
    }

    public clickItem(reserve: ReserveStateData): void {
        if (this.isEditMode === true) {
            this.$emit('selected', reserve.reserveItem.id);

            return;
        }

        this.dialogReserve = reserve;
        this.isOpenDialog = true;
    }
}
