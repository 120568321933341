
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DashboardItem extends Vue {
    @Prop({ required: true })
    public title!: string;

    @Prop()
    public bage: number | undefined;

    public onScroll(e: Event): void {
        this.$emit('scroll', e);
    }

    /**
     * スクロール位置をセットする
     */
    public setScrollTop(scrollTop: number): void {
        if (typeof this.$refs.content === 'undefined') {
            return;
        }

        (this.$refs.content as HTMLElement).scrollTop = scrollTop;
    }

    public onClickBage(e: Event): void {
        console.log('click bage');
        if (typeof this.bage !== 'undefined' && this.bage > 0) {
            this.$emit('bage', e);
        }
    }
}
