import { render, staticRenderFns } from "./OnAir.vue?vue&type=template&id=fbc8e056&scoped=true&"
import script from "./OnAir.vue?vue&type=script&lang=ts&"
export * from "./OnAir.vue?vue&type=script&lang=ts&"
import style0 from "./OnAir.vue?vue&type=style&index=0&id=fbc8e056&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc8e056",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VMain,VTab,VTabItem,VTabs,VTabsItems})
