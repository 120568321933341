




















































































import DropLogDialog from '@/components/dropLog/DropLogDialog.vue';
import RecordedDetailEncodeButton from '@/components/recorded/detail/RecordedDetailEncodeButton.vue';
import RecordedDetailKodiButton from '@/components/recorded/detail/RecordedDetailKodiButton.vue';
import RecordedDetailMoreButton from '@/components/recorded/detail/RecordedDetailMoreButton.vue';
import RecordedDetailPlayButton from '@/components/recorded/detail/RecordedDetailPlayButton.vue';
import RecordedDetailSelectStreamDialog from '@/components/recorded/detail/RecordedDetailSelectStreamDialog.vue';
import RecordedDetailStopEncodeButton from '@/components/recorded/detail/RecordedDetailStopEncodeButton.vue';
import TitleBar from '@/components/titleBar/TitleBar.vue';
import container from '@/model/ModelContainer';
import ISocketIOModel from '@/model/socketio/ISocketIOModel';
import IDropLogDialogState from '@/model/state/dropLog/IDropLogDialogState';
import IScrollPositionState from '@/model/state/IScrollPositionState';
import IRecordedDetailSelectStreamState from '@/model/state/recorded/detail/IRecordedDetailSelectStreamState';
import { RecordedDisplayData } from '@/model/state/recorded/IRecordedUtil';
import ISnackbarState from '@/model/state/snackbar/ISnackbarState';
import { ISettingStorageModel, ISettingValue } from '@/model/storage/setting/ISettingStorageModel';
import Util from '@/util/Util';
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as apid from '../../../api';
import IRecordedDetailState from '../model/state/recorded/detail/IRecordedDetailState';

Component.registerHooks(['beforeRouteUpdate', 'beforeRouteLeave']);

@Component({
    components: {
        TitleBar,
        RecordedDetailPlayButton,
        RecordedDetailEncodeButton,
        RecordedDetailStopEncodeButton,
        RecordedDetailMoreButton,
        RecordedDetailSelectStreamDialog,
        RecordedDetailKodiButton,
        DropLogDialog,
    },
})
export default class RecordedDetail extends Vue {
    public isHideExtend = false;
    public isOpenDropLogDialog = false;

    public recordedDetailState: IRecordedDetailState = container.get<IRecordedDetailState>('IRecordedDetailState');
    private dropLogState: IDropLogDialogState = container.get<IDropLogDialogState>('IDropLogDialogState');
    private setting: ISettingStorageModel = container.get<ISettingStorageModel>('ISettingStorageModel');
    private settingValue: ISettingValue | null = null;
    private scrollState: IScrollPositionState = container.get<IScrollPositionState>('IScrollPositionState');
    private snackbarState: ISnackbarState = container.get<ISnackbarState>('ISnackbarState');
    private socketIoModel: ISocketIOModel = container.get<ISocketIOModel>('ISocketIOModel');
    private onUpdateStatusCallback = (async (): Promise<void> => {
        await this.fetchData();
    }).bind(this);
    public streamSelectDialogState: IRecordedDetailSelectStreamState = container.get<IRecordedDetailSelectStreamState>('IRecordedDetailSelectStreamState');

    get recorded(): RecordedDisplayData | null {
        return this.recordedDetailState.getRecorded();
    }

    public created(): void {
        this.settingValue = this.setting.getSavedValue();

        // socket.io イベント
        this.socketIoModel.onUpdateState(this.onUpdateStatusCallback);
    }

    public beforeDestroy(): void {
        // socket.io イベント
        this.socketIoModel.offUpdateState(this.onUpdateStatusCallback);
    }

    public async showDropLog(): Promise<void> {
        const recorded = this.recordedDetailState.getRecorded();
        if (recorded === null || typeof recorded.recordedItem.dropLogFile === 'undefined') {
            return;
        }

        this.dropLogState.setName(recorded.display.name);
        try {
            await this.dropLogState.fetchData(recorded.recordedItem.dropLogFile.id);
            this.isOpenDropLogDialog = true;
        } catch (err) {
            this.snackbarState.open({
                color: 'error',
                text: 'ログファイル取得に失敗しました',
            });
        }
    }

    public play(video: apid.VideoFile): void {
        if (video.type === 'encoded' && this.setting.getSavedValue().isPreferredPlayingOnWeb === true) {
            Util.move(this.$router, {
                path: '/recorded/watch',
                query: {
                    videoId: video.id.toString(10),
                    recordedId: this.$route.params.id,
                },
            });

            return;
        }

        const url = this.recordedDetailState.getVideoURL(video);

        location.href = url !== null ? url : this.recordedDetailState.getVideoPlayListURL(video);
    }

    public streaming(video: apid.VideoFile): void {
        this.streamSelectDialogState.open(video, parseInt(this.$route.params.id, 10));
    }

    public downloadVideo(video: apid.VideoFile): void {
        const url = this.recordedDetailState.getVideoDownloadURL(video);

        location.href = url !== null ? url : this.recordedDetailState.getVideoDownloadRawURL(video);
    }

    public downloadPlayList(video: apid.VideoFile): void {
        location.href = this.recordedDetailState.getVideoPlayListURL(video);
    }

    public async stopEncode(): Promise<void> {
        try {
            await this.recordedDetailState.stopEncode();
            this.snackbarState.open({
                color: 'success',
                text: 'エンコード停止',
            });
        } catch (err) {
            this.snackbarState.open({
                color: 'error',
                text: 'エンコード停止に失敗',
            });
        }
    }

    @Watch('$route', { immediate: true, deep: true })
    public onUrlChange(): void {
        this.recordedDetailState.clearData();
        this.$nextTick(async () => {
            await this.fetchData().catch(err => {
                this.snackbarState.open({
                    color: 'error',
                    text: '録画データ取得に失敗',
                });
                console.error(err);
            });

            // データ取得完了を通知
            await this.scrollState.emitDoneGetData();
        });
    }

    /**
     * データ取得
     */
    private async fetchData(): Promise<void> {
        await this.recordedDetailState.fetchData(parseInt(this.$route.params.id, 10), this.settingValue === null ? true : this.settingValue.isHalfWidthDisplayed);

        // 番組詳細 URL 処理
        this.$nextTick(() => {
            this.isHideExtend = true;
            this.$nextTick(() => {
                this.isHideExtend = false;
                this.$nextTick(() => {
                    if (typeof this.$refs.extend !== 'undefined') {
                        let str = (this.$refs.extend as Element).innerHTML;
                        str = str.replace(/(http:\/\/[\x21-\x7e]+)/gi, "<a href='$1' target='_blank'>$1</a>");
                        str = str.replace(/(https:\/\/[\x21-\x7e]+)/gi, "<a href='$1' target='_blank'>$1</a>");
                        (this.$refs.extend as Element).innerHTML = str;
                    }
                });
            });
        });
    }
}
